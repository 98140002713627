<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement">
         <div class="container">
            <div class="text-center">
               <h1 class="mb-0">{{contentlist.payout_content1}}</h1>
               
               
               <!-- Apec Start -->
               <p class="d-block mt-3 mt-mb-4 mb-0" v-if="entityName == 'apec'">
                    {{contentlist.payout_apec_content2}}
               </p>
               <!-- Apec End -->
               <!-- Global Start -->
               <p class="d-block mt-3 mt-mb-4 mb-0" v-else>
                     {{contentlist.payout_global_content2}}
               </p>
               <!-- Global End -->
            </div>
            <div class="d-block d-md-flex mt-4 payoutBoxMain" :class="(entityName == 'global') ? 'payoutBoxMainDMCC' :''">
               <div class="payoutBox">
                  <div class="payoutTitle d-flex align-items-center shadow-md p-3 rounded-pill m-auto m-md-0">
                     <h6 class="primarybg rounded-pill text-center text-white pt-2 pt-2 regular circleTag mb-0">{{contentlist.one_content}}</h6>
                     <h6 class="ps-3 mb-0">{{contentlist.payout_content3}}</h6>
                  </div>
                  <div class="payoutContent">
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/btc.webp" class="img-fluid d-block" alt="bitcoin"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.bitcoin_content}}</h6>
                           <p class="m-0 lh-base">{{contentlist.btc_content}}</p>
                        </div>
                     </div>
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/eth.webp" class="img-fluid d-block" alt="Ethereum"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.ethereum_content}}</h6>
                           <p class="m-0 lh-base">{{contentlist.eth_content}}</p>
                        </div>
                     </div>
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/ltc.webp" class="img-fluid d-block" alt="Litecoin"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.litecoin_content}}</h6>
                           <p class="m-0 lh-base">{{contentlist.ltc_content}}</p>
                        </div>
                     </div>
                     <p class="d-block mt-4 mb-0 text-center">{{contentlist.payout_content4}}</p>
                  </div>
               </div>
               <div class="payoutBox">
                  <div class="payoutTitle d-flex align-items-center shadow-md p-3 rounded-pill m-auto m-md-0">
                     <h6 class="primarybg rounded-pill text-center text-white pt-2 regular circleTag mb-0">{{contentlist.two_content}}</h6>
                     <h6 class="ps-3 mb-0">{{contentlist.payout_content5}}</h6>
                  </div>
                  <div class="payoutContent">
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/usdt.webp" class="img-fluid d-block" alt="Tether"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.tether_content}}</h6>
                           <p class="m-0 lh-base">{{contentlist.usdt_content}}</p>
                        </div>
                     </div>
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/usdc.webp" class="img-fluid d-block" alt="USD-COIN"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.usd_coin_content}}</h6>
                           <p class="m-0 lh-base">{{contentlist.usdc_content}}</p>
                        </div>
                     </div>
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/dai.webp" class="img-fluid d-block" alt="DAI"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.dai_content}}</h6>
                           <p class="m-0 lh-base">{{contentlist.dai_content}}</p>
                        </div>
                     </div>
                     <p class="d-block mt-4 mb-0 text-center">{{contentlist.payout_content4}}</p>
                  </div>
               </div>
               <div class="payoutBox" v-if="entityName == 'apec'">
                  <div class="payoutTitle d-flex align-items-center shadow-md p-3 rounded-pill m-auto m-md-0">
                     <h6 class="primarybg rounded-pill text-center text-white pt-2 regular circleTag mb-0">{{contentlist.three_content}}</h6>
                     <h6 class="ps-3 mb-0">{{contentlist.fiat_content}}</h6>
                  </div>
                  <div class="payoutContent">
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/SWIFT.webp" class="img-fluid d-block" alt="SWIFT"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.swift_content}}</h6>
                        </div>
                     </div>
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/SEPA.webp" class="img-fluid d-block" alt="SEPA"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.sepa_content}}</h6>
                        </div>
                     </div>
                     <div class="d-flex align-items-center round-sm shadow-sm bg-white unlockCryptoBox mt-3">
                        <div class="cryptoIcon"><img src="/assets/images/crypto-icons/international-transfer.webp" class="img-fluid d-block" alt="international transfer"></div>
                        <div class="ms-3">
                           <h6 class="m-0 lh-base">{{contentlist.international_transfer_content}}</h6>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="d-flex justify-content-center mt-0 mt-md-4"><a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium">{{contentlist.register_now_content}}</a></div>
         </div>
      </section>
      <section class="blockElement lightgreybg globalPayoutSection">
         <h2 class="mb-0 text-center">{{contentlist.payout_content6}}</h2>
         <Carousel class="globalPayoutCarousel mt-2 mt-md-4" :wrap-around="true" :settings="globalPayoutCarousel" :autoplay="2000">
            <Slide key="1">
               <div class="carousel__item">
                  <div class="globalPayoutBox round-sm shadow-md bg-white mt-3 text-center me-5">
                     <div class="globalPayoutIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto"><img src="/assets/images/convenient.webp" alt="convenient" class="img-fluid" /></div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.payout_content7}}</h5>

                    

                     <!-- APEC Start -->
                     <p class="m-0 lh-base pt-4" v-if="entityName == 'apec'">{{contentlist.payout_apec_content8}}</p>
                     <!-- APEC End -->
                      <!-- Global Start -->
                     <p class="m-0 lh-base pt-4" v-else>{{contentlist.payout_global_content8}}</p>
                      <!-- Global End -->

                  </div>
               </div>
            </Slide>
            <Slide key="2">
               <div class="carousel__item">
                  <div class="globalPayoutBox round-sm shadow-md bg-white mt-3 text-center me-5">
                     <div class="globalPayoutIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto"><img src="/assets/images/efficient.webp" alt="convenient" class="img-fluid" /></div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.payout_content9}}</h5>
                     <p class="m-0 lh-base pt-4">{{contentlist.payout_content10}}</p>
                  </div>
               </div>
            </Slide>
            <Slide key="3">
               <div class="carousel__item">
                  <div class="globalPayoutBox round-sm shadow-md bg-white mt-3 text-center me-5">
                     <div class="globalPayoutIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto"><img src="/assets/images/transparent.webp" alt="convenient" class="img-fluid" /></div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.payout_content11}}</h5>
                     <p class="m-0 lh-base pt-4">{{contentlist.payout_content12}}</p>
                  </div>
               </div>
            </Slide>
            <Slide key="4">
               <div class="carousel__item">
                  <div class="globalPayoutBox round-sm shadow-md bg-white mt-3 text-center me-5">
                     <div class="globalPayoutIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto"><img src="/assets/images/instant.webp" alt="convenient" class="img-fluid" /></div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.payout_content13}}</h5>
                     <p class="m-0 lh-base pt-4">{{contentlist.payout_content14}}</p>
                  </div>
               </div>
            </Slide>
            <Slide key="5">
               <div class="carousel__item">
                  <div class="globalPayoutBox round-sm shadow-md bg-white mt-3 text-center me-5">
                     <div class="globalPayoutIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto"><img src="/assets/images/user-friendly.webp" alt="convenient" class="img-fluid" /></div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.payout_content15}}</h5>
                     <p class="m-0 lh-base pt-4">{{contentlist.payout_content16}}</p>
                  </div>
               </div>
            </Slide>
            <template #addons>
               <Navigation />
               <Pagination />
            </template>
         </Carousel>
         <div class="d-flex justify-content-center mt-3 mt-md-4"><a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium">{{contentlist.start_now_content}}</a></div>
      </section>
      <section class="blockElement">
         <div class="container">
            <div class="text-center">
               <h2 class="mb-0">{{contentlist.payout_content17}}</h2>
               <p class="mb-0">{{contentlist.payout_content18}}</p>
            </div>
            <div class="tab d-flex justify-content-center mt-5 hideMobile">
               <ul class="nav nav-pills">
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 1}]" @click.prevent="tab = 1">{{contentlist.payout_content19}}</a>
                  </li>
                  <li class="nav-item position-relative">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 2}]" @click.prevent="tab = 2">{{contentlist.payout_content20}}</a>
                     <p class="tabComingSoon secondarycolor redbg round-md whitecolor mb-0 px-3">Coming Soon</p>
                  </li>
               </ul>
            </div>
            <div class="tabContent mt-4 mt-lg-5">
               <div class="showTabs-in-mob" :class="[{'active':tab == 1}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 1">
                     {{contentlist.payout_content19}}
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 1" class="pt-4 pt-lg-0">
                  <div class="row d-flex align-items-center flex-row-reverse">
                     <div class="col-md-7 col-xl-6">
                        <Carousel class="payoutCarousel m-auto round-md">
                           <Slide key="6">
                              <div class="carousel__item position-relatives">
                                 <div class="payoutCarouselBox text-center text-md-start w-100">
                                    <h3>{{contentlist.step_one_content}}</h3>
                                    <p>{{contentlist.payout_content21}}</p>
                                    <img src="/assets/images/payout-coins.webp" alt="enjoy freedom" class="img-fluid payoutCoins position-absolute" />
                                 </div>
                              </div>
                              
                           </Slide>
                           <Slide key="7">
                              <div class="carousel__item position-relatives">
                                 <div class="payoutCarouselBox text-center text-md-start w-100">
                                    <h3>{{contentlist.step_two_content}}</h3>
                                    <p>{{contentlist.payout_content22}}</p>
                                    <img src="/assets/images/payout-withdraw.webp" alt="enjoy freedom" class="img-fluid payoutCarouselImg payoutAmount position-absolute" />
                                 </div>
                              </div>
                              
                           </Slide>
                           <Slide key="8">
                              <div class="carousel__item position-relatives">
                                 <div class="payoutCarouselBox text-center text-md-start w-100">
                                    <h3>{{contentlist.step_three_content}}</h3>
                                    <p>{{contentlist.payout_content23}}</p>
                                    <img src="/assets/images/payout-address.webp" alt="enjoy freedom" class="img-fluid payoutCarouselImg payoutAccount position-absolute" />
                                 </div>
                              </div>
                           </Slide>
                           <Slide key="9">
                              <div class="carousel__item position-relatives">
                                 <div class="payoutCarouselBox text-center text-md-start w-100">
                                    <h3>{{contentlist.done_content}}</h3>
                                    <p>{{contentlist.payout_content24}}</p>
                                    <img src="/assets/images/payout-settlements.webp" alt="enjoy freedom" class="img-fluid payoutCarouselImg position-absolute" />
                                 </div>
                              </div>
                           </Slide>
                           <template #addons>
                              <Navigation />
                              <Pagination />
                           </template>
                        </Carousel>
                     </div>
                     <div class="col-md-5 col-xl-6 mt-5 mt-md-0 pt-5 pt-md-0">
                        <div class="pe-0 pe-lg-5 text-center text-md-start">
                           <h2>{{contentlist.payout_content25}}</h2>
                           <p class="mt-3">{{contentlist.payout_content26}}</p>
                           <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium mt-2 mt-md-3">{{contentlist.join_now_content}}</a>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="showTabs-in-mob pt-3 pb-lg-0" :class="[{'active':tab == 2}]">
                  <a class="nav-link round-sm px-3 py-2 semibold position-relative" href="javascript:void(0);" @click.prevent="tab = 2">
                     {{contentlist.payout_content20}} <p class="tabComingSoon secondarycolor redbg round-md whitecolor mb-0 px-3">Coming Soon</p>
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 2" class="pt-4 pt-lg-0">
                  <div class="row d-flex align-items-center flex-row-reverse">
                     <div class="col-md-7 col-xl-6">
                        <Carousel class="payoutCarousel m-auto round-md">
                           <Slide :key="slide">
                              <div class="carousel__item position-relatives">
                                 <div class="payoutCarouselBox text-center text-md-start w-100">
                                    <h3>{{contentlist.step_one_content}}</h3>
                                    <p>{{contentlist.payout_content27}}</p>
                                    <img src="/assets/images/payout-calendar.webp" alt="enjoy freedom" class="img-fluid payoutCarouselImg payoutCalendar position-absolute" />
                                 </div>
                              </div>
                           </Slide>
                           <Slide :key="slide">
                              <div class="carousel__item position-relatives">
                                 <div class="payoutCarouselBox text-center text-md-start w-100">
                                    <h3>{{contentlist.step_two_content}}</h3>
                                    <p>{{contentlist.payout_content21}}</p>
                                    <img src="/assets/images/payout-coins.webp" alt="enjoy freedom" class="img-fluid payoutCoins position-absolute" />
                                 </div>
                              </div>
                           </Slide>
                           <Slide :key="slide">
                              <div class="carousel__item position-relatives">
                                 <div class="payoutCarouselBox text-center text-md-start w-100">
                                    <h3>{{contentlist.step_three_content}}</h3>
                                    <p>{{contentlist.payout_content28}}</p>
                                    <img src="/assets/images/payout-account.webp" alt="enjoy freedom" class="img-fluid payoutCarouselImg payoutAccount position-absolute" />
                                 </div>
                              </div>
                           </Slide>
                           <Slide :key="slide">
                              <div class="carousel__item position-relatives">
                                 <div class="payoutCarouselBox text-center text-md-start w-100">
                                    <h3>{{contentlist.done_content}}</h3>
                                    <p>{{contentlist.payout_content29}}</p>
                                    <img src="/assets/images/payout-settlements.webp" alt="enjoy freedom" class="img-fluid payoutCarouselImg position-absolute" />
                                 </div>
                              </div>
                           </Slide>
                           <template #addons>
                              <Navigation />
                              <Pagination />
                           </template>
                        </Carousel>
                     </div>
                     <div class="col-md-5 col-xl-6 mt-5 mt-md-0 pt-5 pt-md-0">
                        <div class="pe-0 pe-lg-5 text-center text-md-start">
                           <h2>{{contentlist.payout_content30}}</h2>
                           <p class="mt-3">{{contentlist.payout_content31}}</p>
                           <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium mt-2 mt-md-3">{{contentlist.join_now_content}}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="mt-5 pt-3">
               <div class="row">
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content5}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content6}} <br> {{contentlist.flexible_box_content7}} </p>
                        </div>
                        <router-link :to="entityPath+'/crypto-conversion'" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.find_out_more_content}}</router-link>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content14}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content15}}<br>{{contentlist.flexible_box_content16}}</p>
                        </div>
                        <a href="javascript:void(0);" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.read_more_content}}</a>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content17}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content9}} </p>
                        </div>
                        <router-link :to="entityPath+'/contact-us'" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.contact_us_content}}</router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                        <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                        <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
import "vue3-carousel/dist/carousel.css";
   import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
   export default ({
      mixins: [ commonAllmixins],   //anamica
      name: "Autoplay",
      data() {
         return {
            tab: 1,
            globalPayoutCarousel: {
                     snapAlign: 'start',
                     breakpoints: {
                        700: {
                           itemsToShow: 1,
                           snapAlign: 'start',
                        },
                        992: {
                           itemsToShow: 2,
                           snapAlign: 'start',
                        },
                        1400: {
                           itemsToShow: 3,
                           snapAlign: 'start',
                        },
                        1800: {
                           itemsToShow: 4,
                           snapAlign: 'start',
                        },
                     },
            },
            
            
         };
      },
      created(){
         console.log(this.contentlist)
      },
      components: { Carousel, Slide, Pagination, Navigation },
   });
</script>